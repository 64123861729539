.App {
    display: flex;
    font-family: sans-serif;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container {
    width: 25%;
}

.form-container {
    width: 50%;
}

.steps-container {
    display: flex;
    flex-direction: column;
}

.steps-item {
    font-family: 'Argon-Regular', sans-serif;
    font-size: 15px;
    color: #5C5C57;
    min-height: 49px;
    cursor: pointer;
}

.vertical-slider {
    height: 100px;
    width: 25%;
    /* border: 1px solid grey; */
}
  